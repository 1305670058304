import React from 'react'
import { PageTransition } from '../../components/Transitions/PageTransitions';

// Contexts
import { AppContext } from '../../contexts/Survey';

//Services
import { getSurvey, setSurvey } from '../../services/Rest';
import SurveyModel from '../../services/Survey';
import {PageButton, PageLoading } from "../../components/Page/Page";
import {ZwanTradeMark} from "../../components/domain/Zwan/Zwan";
import logo from "../../components/domain/Zwan/assets/img/logo.png";
import {ctaIcon, playIcon} from "../../components/Page/Icons";

class Answers extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            answers: props.data,
            questionId: props.questionId,
            nextQuestion: props.nextQuestion,
            choice: {
                name: null,
                value: null
            }
        }
    }

    handleCheckbox = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            choice: {
                name, value: parseInt(value)
            }
        })
    }

    submitAnswer = (answerId, value) => {
        this.context.setAppContext(app => {

            let answers = app.survey;
            answers[`${answerId}`] = value;

            return {
                participant: app.participant,
                survey: answers
            }

        })
    }

    next = () => {
        this.submitAnswer(this.state.choice.name, this.state.choice.value);
        this.state.nextQuestion();
    }


    render() {
        return (
            <div className="mt-10">
                {this.state.answers.map((answer) => (
                    <div className="block" key={answer.id}>
                        <div className="mt-2 sm:mt-3">
                            <label className="flex space-x-4">
                                <div className="">
                                    <input
                                        type="radio"
                                        className="form-radio h-6 w-6 sm:h-8 sm:w-8"
                                        name={this.state.questionId}
                                        key={answer.id}
                                        value={answer.id}
                                        onChange={this.handleCheckbox}/>
                                </div>
                                <div className="">
                                    <span className="sm:text-xl">{answer.text}</span>
                                </div>
                            </label>
                        </div>
                </div>
                ))}
                <div className="mt-10 ">
                    <PageButton
                        className=" disabled:opacity-50 px-2 py-3 text-center text-white bg-gold rounded-lg hover:text-white font-bold text-sm"
                        label="Continua →"
                        containerMargin={0}
                        onClickMethod={this.next}
                        disabled={this.state.choice.name}
                    >
                    </PageButton>
                </div>
            </div>
        );
    }
}

Answers.contextType = AppContext;

class Questions extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            questions: props.data,
            actualQuestion: 0,
            showAnswers: true,
            nextModule: props.nextModule
        }
    }

    actualQuestion = () => this.state.questions[this.state.actualQuestion];

    nextQuestion = () => {
        if((this.state.actualQuestion + 1) === this.state.questions.length)
            return this.state.nextModule();

        this.setState( (state) => state.actualQuestion += 1 )
    }

    render() {
        return (
            <div>
                {
                    this.state.questions.map(question => (
                        <React.Fragment key={question.id.toString()}>
                        {(this.actualQuestion().id === question.id) && (
                            <div className="single-question" key={`question-${question.id}`}>
                                <h2 className="text-xl sm:text-2xl" dangerouslySetInnerHTML={{__html: question.text}}></h2>
                                <Answers 
                                    key={`answers-${question.id}`}
                                    questionId={question.id} 
                                    data={question.answers}
                                    nextQuestion={this.nextQuestion} />
                            </div>
                        )}
                        </React.Fragment>
                    ))
                }
            </div>
        )
    }
}

class Modules extends React.Component {
    
    constructor(props) {
        super(props)
        
        const survey = props.data;

        this.state = {
            modules: survey.modules(),
            actualModule: 0,
            countModules: survey.modules().length,
            endSurvey: props.endSurvey
        }
    }

    actualModule = () => this.state.modules[this.state.actualModule];

    nextModule = () => {
        if( (this.state.actualModule+1) === this.state.modules.length )
            return this.state.endSurvey()

        this.setState((state) => state.actualModule += 1)
    }
    
    render() {
        return(
            <div>
                {this.state.modules.map((module, idx) => (
                    <React.Fragment key={module.id}>
                        {(module.id === this.actualModule().id) && (
                        <div className="single-module">
                            {module.cta_label ? (
                                <div className="absolute bottom-10 right-10">
                                    <div>
                                        <a target="_blank" href={module.cta_link}>
                                            <PageButton
                                                className="mt-4 text-center text-white bg-blue hover:text-white font-bold text-sm"
                                                label={module.cta_label + " →"}  />
                                        </a>
                                    </div>
                                </div>
                            ) : ""}
                            <div className="mb-4 sm:mb-10 grid grid-cols-3">
                                <div>
                                    <img className="h-32 sm:h-48 object-contain " src={module.picture_url} />
                                </div>
                            </div>
                            <h5 className="text-gray-400 text-sm" >
                                <span>{idx+1} / {this.state.countModules} &#8594; </span>
                                {this.actualModule().title}
                            </h5>
                            <Questions data={module.questions} nextModule={this.nextModule}></Questions>
                        </div>
                        )}
                    </React.Fragment>
                ))}
            </div>            
        )
    }
}

class Survey extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            startSurvey: false,
            survey: {},
            isLoading: false
        }

        this.loadSurvey();
    }

    loadSurvey = async () => {
        const surveyData = await getSurvey();
        this.setState((state) => {
            state.survey = new SurveyModel(surveyData)
            state.startSurvey = true;

            return state;
        });
    }

    endSurvey = async () => {

        this.setState({isLoading: true})

        const response = await setSurvey({
            participant_id: this.context.app.participant.id,
            answers: this.context.app.survey,
            goals: {}
        })

        this.setState({isLoading: false})

        console.log(response);

        this.props.history.push(
            `/confirm/${response.data.submissionId}`,
            {
                gradeTitle: response.data.grade.title,
                gradePicture: response.data.grade.picture,
                gradeDescription: response.data.grade.description
            });
    }

    render() {
        return(
            <PageTransition>
                <PageLoading active={this.state.isLoading}></PageLoading>
                <ZwanTradeMark></ZwanTradeMark>
                <div className="mx-6 sm:mx-2">
                    { this.state.startSurvey && (
                    <div className="h-screen flex flex-col items-left justify-center text-white">
                        <Modules data={this.state.survey} endSurvey={this.endSurvey}></Modules>
                    </div>
                )}
            </div>
            </PageTransition>
        )
    }
}

Survey.contextType = AppContext;

export default Survey