import logo from './assets/img/logo.png';
import noobImg from './assets/img/noob_cta.png';
import fanImg from './assets/img/fan_cta.png';
import geekImg from './assets/img/geek_cta.png';

import {motion} from 'framer-motion';
import {PageButton} from "../../Page/Page";
import {Link} from "react-router-dom";

export const ZwanLogo = ({h = 24, animated = true}) => (
    <motion.div animate={{ scale: 1.1 }} transition={{ duration: 2 }}>
        <div className="mb-6 lg:mb-10">
            <img className="h-24 md:h-36 lg:h-52 w-full object-contain mb-5" src={logo} alt="Zwan Logo" />                  
        </div>
    </motion.div>
)

export const ZwanTradeMark = () => {
    return (
        <div className="absolute top-10 right-10">
            <a target="_blank" href={"https://www.zwan.it"}>
                <img className="h-10 object-contain " src={logo} alt="Zwan Logo" />
            </a>
        </div>
    )
}

export const NoobImage = () => (
    <img className="h-36 md:h-36 lg:h-52 object-contain" src={noobImg} />
)

export const FanImage = () => (
    <img className="h-36 md:h-36 lg:h-52 object-contain" src={fanImg} />
)

export const GeekImage = () => (
    <img className="h-36 md:h-36 lg:h-52 object-contain" src={geekImg} />
)

export const NoobCTA = () => (

    <div className="mt-10 sm:mt-20 bg-white w-full sm:flex sm:flex-wrap content-center items-center p-3 rounded-xl shadow border">
        <div className="flex justify-center sm:justify-start sm:w-1/3 items-center">
            <NoobImage />
        </div>
        <div className="mt-4 sm:mt-0 text-center sm:text-left w-full sm:w-1/3">
            <div className="pb-2 font-semibold text-gray-700">
                TUTTA LA REPUTAZIONE<br /> TUTTA IN UN LIBRO
            </div>
            <div className="text-sm text-gray-500 mx-3 sm:mx-0">
                Reputazione Capitale del Terzo Millennio è la guida definitiva al mondo della Reputazione.
            </div>
        </div>
        <div className="flex justify-center w-full sm:w-1/3">
            <a href={"https://membership.zwan.it/checkout/?add-to-cart=384"}>
                <button className="mt-4 sm:mt-0 bg-gold py-4 px-6 rounded-full inline-flex items-center" >
                    <span> Voglio una copia →</span>
                </button>
            </a>
        </div>
    </div>

)


export const FanCTA = () => (

    <div className="mt-10 sm:mt-20 bg-white w-full sm:flex sm:flex-wrap content-center items-center p-3 rounded-xl shadow border">
        <div className="flex justify-center sm:justify-start sm:w-1/3 items-center">
            <FanImage></FanImage>
        </div>
        <div className="mt-4 sm:mt-0 text-center sm:text-left w-full sm:w-1/3">
            <div className="pb-2 font-semibold text-gray-700">
                REPUTATION KIT
            </div>
            <div className="text-sm text-gray-500 mx-3 sm:mx-0">
                Reputation Kit è lo “starter pack” per il mondo della Reputazione.
            </div>
        </div>
        <div className="flex justify-center w-full sm:w-1/3">
            <a href={"https://membership.zwan.it/checkout/?add-to-cart=387"}>
                <button className="mt-4 sm:mt-0 bg-gold py-4 px-6 rounded-full inline-flex items-center" >
                    <span> Richiedi il Kit →</span>
                </button>
            </a>
        </div>
    </div>

)

export const GeekCTA = () => (

    <div className="mt-7 sm:mt-20 flex flex-col justify-center text-center gap-y-4 w-full sm:w-1/3">
        <Link to={'/thankyou/appuntamento'}>
            <button className="mt-4 sm:mt-0 bg-gold py-4 px-6 rounded-full" >
                <span> Desidero fissare un appuntamento →</span>
            </button>
        </Link>
        <Link to={'/thankyou/scontoRiservato'}>
            <button className="mt-4 sm:mt-0 text-sm" >
                <span> Non voglio fissare un appuntamento →</span>
            </button>
        </Link>
    </div>

)
