import React from 'react';

// Components
import { PageButton, PageSubtitle, PageTitle } from '../../components/Page/Page';
import { ZwanLogo } from '../../components/domain/Zwan/Zwan';

// Utils
import { playIcon } from '../../components/Page/Icons';

import { Link, NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';  
import { PageTransition } from '../../components/Transitions/PageTransitions';
import { SoftZoomInOnHover } from '../../components/Transitions/ComponentTransitions';

/**
 * Introduction View Component
 */
export default class Introduction extends React.Component {

    render() {
        return (
                <PageTransition>
                <div className="h-screen flex flex-col items-center justify-center text-white">
            
                    <ZwanLogo />
                    <PageTitle text='Reputation Selfie' />
                    <PageSubtitle className="mt-4" text="Scopri il valore della tua Reputazione" />
                    <PageSubtitle className=" " text="in meno di 10 minuti" />

                    <SoftZoomInOnHover>
                        <Link to={'/signup'}><PageButton disabled={false} className="bg-gold" label="Inizia il test" icon={playIcon} /></Link>
                    </SoftZoomInOnHover>
                    
                </div>
                </PageTransition>
            
            );
    }
}