
export default class SurveyModel {

    constructor( obj ) {
        if(! 'data' in obj )
            throw new Error('Error in Survey response data');

        this.survey = obj.data.data;
    }

    currentRevision = () => this.survey.current_revision;

    modules = () => this.currentRevision().modules;

    questions = (module) => module.questions

}