import './App.css';

// Contexts
import { AppContextProvider } from './contexts/Survey';

// Components
import Introduction from './view/Introduction/Introduction';
import SignUp from './view/SignUp/SignUp';
import Survey from './view/Survey/Survey';
import Confirm from './view/Confirm/Confirm';
import Appuntamento from "./view/Thankyou/Appuntamento";
import ScontoRiservato from "./view/Thankyou/ScontoRiservato";

import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';

function App() {

  const location = useLocation();

  return (
    <AppContextProvider>
      <div className="App container mx-auto">
          <AnimatePresence exitBeforeEnter initial={false}>

            <Switch location={location} key={location.pathname}>
              <Route exact path="/" component={Introduction} />
              <Route path="/signup" component={SignUp} />
              <Route path="/survey" component={Survey} />
              <Route path="/confirm" component={Confirm} />
              <Route path="/thankyou/appuntamento" component={Appuntamento} />
              <Route path="/thankyou/scontoRiservato" component={ScontoRiservato} />
            </Switch>
            
          </AnimatePresence>
      </div>
    </AppContextProvider>
  );
}

export default App;
