import React from 'react'
import {PageButton, PageTitle} from "../../components/Page/Page";
import {PageTransition} from "../../components/Transitions/PageTransitions";

import {motion} from 'framer-motion';

import { withRouter } from 'react-router-dom'

import {getImagePath} from "../../services/Rest";
import {NoobCTA, FanCTA, GeekCTA, ZwanTradeMark} from "../../components/domain/Zwan/Zwan";
import {trackLead} from "../../services/FacebookPixel";

class Confirm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            gradeTitle: this.props.location.state.gradeTitle,
            imageRef: this.props.location.state.gradePicture,
            description: this.props.location.state.gradeDescription
            // gradeTitle: "Reputation Noob",
            // imageRef: "y90fKTLYC65YYWDpRdBc4fyssnkTigmUZg88EjYg.png",
            // description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In gravida orci vel leo aliquet iaculis. Suspendisse auctor odio orci, eu congue sem feugiat quis. Cras at volutpat ante. Integer varius a lectus sed ornare."
        }

    }

    componentDidMount() {
        trackLead();
    }

    render() {

        return(
            <PageTransition>
                <ZwanTradeMark></ZwanTradeMark>
                <div className="my-12 sm:my-0 h-screen mx-4 sm:mx-0 flex flex-col items-center justify-center text-white">
                    <motion.div animate={{ scale: 1.1 }} transition={{ duration: 2 }}>
                        <div className="mb-6 lg:mb-10">
                            <img className="h-28 md:h-36 lg:h-52 w-full object-contain mb-5" src={getImagePath(this.state.imageRef)} alt="Reputation Score" />
                        </div>
                    </motion.div>
                    <PageTitle text={this.state.gradeTitle}></PageTitle>
                    <div className="text-center" dangerouslySetInnerHTML={{__html: this.state.description}}></div>

                    {this.state.gradeTitle == 'Reputation Noob' ? (<NoobCTA />) : ""}
                    {this.state.gradeTitle == 'Reputation Fan' ? (<FanCTA />) : ""}
                    {this.state.gradeTitle == 'Reputation Geek' ? (<GeekCTA />) : ""}

                </div>
            </PageTransition>
        )
    }
}

export default withRouter(Confirm);