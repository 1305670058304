import { SignUpSchema } from '../../services/Schemas';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { PageLabel } from '../../components/Page/Page';

/**
 * Input Style
 */
const fieldInputStyle = "px-1 py-2 placeholder-white-400 text-gray-800 relative bg-transparent text-md outline-none focus:outline-none focus:shadow-outline w-full"
const fieldErrorStyle = "text-red-500 text-sm"

/**
 * Error field label
 * @param {*} param0 
 */
const PageField = ({fieldName, fieldLabel, fieldType = 'text', placeHolder = ""}) => (
    <div className="ml-6 sm:ml-0">
        <PageLabel text={fieldLabel} />
        <Field 
            type={fieldType} 
            name={fieldName}
            placeholder={placeHolder}
            className={fieldInputStyle} />
        <ErrorMessage name={fieldName} component="div" className={fieldErrorStyle} />
    </div>
)

/**
 * SignUpForm
 * @param {*} param0 
 */
export const SignUpForm = ({onSubmit}) => {

    let initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        role: "",
        company: "",
        privacy: false
    }

    return (
        <Formik initialValues={initialValues} validationSchema={SignUpSchema} onSubmit={onSubmit}>
            { ({isSubmitting, values}) => ( 
                <Form>

                    {/* Anagrafica */}

                    <div className="grid md:grid-cols-3 gap-4">
                        <PageField fieldName="firstName" fieldLabel="Nome" fieldType="text" placeHolder="Mario" />
                        <PageField fieldName="lastName" fieldLabel="Cognome" fieldType="text" placeHolder="Rossi" />
                        <PageField fieldName="email" fieldLabel="Email" fieldType="email" placeHolder="@" />

                    </div>

                    {/* Società */}

                    <div className="grid md:grid-cols-2 sm:gap-4 sm:mt-8">
                        <PageField fieldName="company" fieldLabel="Società" fieldType="text" placeHolder="Zwan srl" />
                        <PageField fieldName="role" fieldLabel="Ruolo" fieldType="text" placeHolder="Manager" />
                    </div>

                    {/* Privacy */}
                    <div className="ml-6 mt-2 sm:ml-0 sm:mt-8">
                        <label>
                            <Field type="checkbox" name="privacy" className="form-checkbox h-3 w-3 text-gray-600" />
                            <span className="text-sm pl-2 text-gray-600 ">Ho letto e accetto l'<a target="_blank" href="https://www.zwan.it/privacy-policy/">Informativa privacy</a> ai sensi del Reg. UE n. 2016/679 </span>
                        </label>
                        <ErrorMessage name="privacy" component="div" className={fieldErrorStyle} />
                    </div>

                     <div className="mt-4 p-4 flex space-x-4 justify-end">
                        <button type="submit" disabled={isSubmitting} className="w-full sm:w-1/2 px-2 py-3 text-center text-white bg-gold rounded-lg hover:bg-gold-700 hover:text-white font-bold text-sm">Inizia il test</button>
                    </div>
                </Form> 
            ) }
        </Formik>
    );
}