import {PageTransition} from "../Transitions/PageTransitions";

/**
 * Title Page Component
 * @param {*} param0 
 */
export const PageTitle = ({text, className}) => 
    <div><h1 className={`font-bold text-4xl md:text-6xl lg:text-8xl ${className}`}> {text} </h1></div>

/**
 * Subtitle Page Component
 * @param {*} param0 
 */
export const PageSubtitle = ({text, className}) => 
    <div className={`${className} text-l`} >{text}</div>

/**
 * Button Page Component
 * @param {*} param0 
 */
export const PageButton = ({label, className, disabled = false, onClickMethod = () => {}, icon = null, containerMargin = 10}) => {
    return (
        <div className={`mt-${containerMargin}`}>
                <button onClick={onClickMethod} className={`${className} py-4 px-6 rounded-full inline-flex items-center`} disabled={!disabled}>
                    { icon }
                    <span> { label } </span>
                </button>
        </div>
    );
}

export const PageLabel = ({text}) => <span className="text-xs font-semibold py-1 px-2 uppercase rounded-full text-gray-600 bg-gray-100 uppercase last:mr-0 mr-1">{text}</span>

/**
 *
 * @param active
 * @returns {JSX.Element}
 * @constructor
 */
export const PageLoading = ({active}) => {
    if(active)
        return (
            <div className="w-full h-full fixed block top-0 left-0 bg-gray-800 opacity-90 z-50">
              <span className="text-white opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0" style={{top: '50%'}}>
                <i className="fas fa-circle-notch fa-spin fa-5x"></i>
              </span>
            </div>
        )
    return ("")
}