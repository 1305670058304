import axios from 'axios';

const apiUrl = (path) => `https://surveybuilder.zwan.it${path}`

const currentSurveyId = 1;

/**
 * API 
 * @param {*} path 
 * @param {*} method 
 * @param {*} data 
 * @param {*} headers 
 */
const api = async ( path, method = 'get', data = {}, headers = {} ) => {
    try {
        const result = await axios.request({
            url: path,
            method,
            data
        });

        return result;
    } catch( error ) {
        console.log(error);
        return false;
    }
}

/**
 * Set Participant REST Service 
 * @param {*} participant 
 */
export const setParticipant = async (participant) => {

    const response = await api(apiUrl('/api/v1/participants'), 'post', {
        first_name: participant.firstName,
        last_name: participant.lastName,
        email: participant.email,
        extra_fields: {
            company: participant.company,
            role: participant.role
        }
    })
    
    return response.data;
}

/**
 * Get Survey Data
 */
export const getSurvey = async () => {
    return await api( apiUrl(`/api/v1/surveys/${currentSurveyId}`), 'get' );
}

/**
 * Set Survey Answer
 * @param {*} data 
 */
export const setSurvey = async (data) => {
    return await api( apiUrl(`/api/v1/surveys/${currentSurveyId}/submissions`), 'post', data);
}

export const getImagePath = (ref) => apiUrl(`/storage/${ref}`)