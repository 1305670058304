import React from 'react';

import { PageButton, PageSubtitle, PageTitle } from '../../components/Page/Page';
import {FanImage, ZwanLogo} from '../../components/domain/Zwan/Zwan';

import { playIcon } from '../../components/Page/Icons';

import { Link, NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { PageTransition } from '../../components/Transitions/PageTransitions';
import { SoftZoomInOnHover } from '../../components/Transitions/ComponentTransitions';

/**
 * Introduction View Component
 */
export default class ScontoRiservato extends React.Component {

    render() {
        return (
            <PageTransition>
                <div className="mx-3 h-screen flex flex-col items-center justify-center text-center text-white">

                    <ZwanLogo />
                    <PageTitle text='Grazie ugualmente!' />
                    <PageSubtitle
                        className="mt-4"
                        text="Abbiamo comunque riservato per te uno sconto esclusivo per il nostro Reputation Kit, una collezione d’eccellenza in continuo aggiornamento, realizzata per fornirti strumenti e strategie da mettere subito in pratica!" />

                    <div className="mt-10 sm:mt-20 bg-white w-full sm:flex sm:flex-wrap content-center items-center p-3 rounded-xl shadow border">
                         <div className="flex justify-center sm:justify-start sm:w-1/3 items-center">
                             <FanImage />
                         </div>
                         <div className="mt-4 sm:mt-0 text-center sm:text-left w-full sm:w-1/3">
                             <div className="pb-2 font-semibold text-gray-700">
                                 REPUTATION KIT <br/>
                                 E DUE CHIACCHIERE
                             </div>
                             <div className="text-sm text-gray-500 mx-3 sm:mx-0">
                                 Reputation Kit è l'esclusivo pack per il mondo della Reputazione.
                             </div>
                         </div>
                         <div className="flex justify-center w-full sm:w-1/3">
                             <a href={"https://membership.zwan.it/checkout/?add-to-cart=473"}>
                                 <button className="mt-4 sm:mt-0 bg-gold py-4 px-6 rounded-full inline-flex items-center" >
                                     <span> Richiedi il Kit →</span>
                                 </button>
                             </a>
                         </div>
                    </div>
                </div>
            </PageTransition>

        );
    }
}