import React from 'react';


/**
 * Survey Context
 */
export const AppContext = React.createContext();

/**
 * App Context Provider Components
 * todo: improve components, specifically the update method 
 * @param {*} props 
 */
export function AppContextProvider(props) {
    const [app, setAppContext] = React.useState({participant: {}, survey: {}})

    const appValue = React.useMemo(() => ({
        app,
        setAppContext
    }), [app])

    return (
        <AppContext.Provider value={appValue}>
            {props.children}
        </AppContext.Provider>
    )
}