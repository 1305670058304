import React from 'react';
import { SignUpForm } from '../../components/Form/Signup';
import { PageTransition } from '../../components/Transitions/PageTransitions';

import { setParticipant } from '../../services/Rest';

//Contexts
import { AppContext } from '../../contexts/Survey';
import {ZwanTradeMark} from "../../components/domain/Zwan/Zwan";

/**
 * SignUp View
 */
class SignUp extends React.Component {

    onSubmit = async (values, { setSubmitting } ) => {
        
        const participant = await setParticipant(values);

        this.context.setAppContext(app => ({ 
            participant: participant.data, 
            survey: app.survey 
        }) );

        this.props.history.push('/survey');
    }

    render() {
        return (
            <PageTransition>
                <ZwanTradeMark></ZwanTradeMark>
                <div className='mx-6 my-16 sm:my-0 h-screen flex flex-col items-left justify-center text-white'>

                    {/* Heading Area */}
                    <div className="my-10" >
                        <h2 className="text-4xl ">Crea il tuo ID</h2>
                        <h6>L'ID Zwan ti permette di accedere in esclusiva a tutti i servizi per curare la tua Reputazione.</h6>
                    </div>

                    {/* SignUp Form Area */}
                    <div className="w-full md:w-5/6 bg-white rounded-lg">
                        <div className="border-t-8 border-gold rounded-lg flex">
                            <div className="w-1/4 ml-4 sm:ml-0 pt-6 flex justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className="w-16 h-16 bg-gold text-white p-3 rounded-full" fill="none" stroke="currentColor">
                                    <path fillRule="evenodd" d="M10 2a1 1 0 00-1 1v1a1 1 0 002 0V3a1 1 0 00-1-1zM4 4h3a3 3 0 006 0h3a2 2 0 012 2v9a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2zm2.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm2.45 4a2.5 2.5 0 10-4.9 0h4.9zM12 9a1 1 0 100 2h3a1 1 0 100-2h-3zm-1 4a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="w-full pt-9 pr-4">
                                <SignUpForm onSubmit={this.onSubmit} />
                            </div>
                        </div>
                    </div>

                </div>
            </PageTransition>
        );
    }
}

SignUp.contextType = AppContext;

export default SignUp;