import * as yup from 'yup';

/**
 * SignupSchema
 */
export const SignUpSchema = yup.object().shape({
  'firstName': yup.string().required('Inserisci il tuo nome'),
  'lastName': yup.string().required('Inserisci il tuo cognome'),
  'email': yup.string().email('Inserisci una mail valida').required('Inserisci la tua email'),
  'company': yup.string().required('Inserisci il nome della società per cui lavori'),
  'role': yup.string().required('Insersci il tuo ruolo'),
  'privacy': yup.bool().oneOf([true], 'Accetta i Termini e le Condizioni per poterti registrare').required('Accetta i Termini e le Condizioni per poterti registrare'),
  'createdOn': yup.date().default(function () {
    return new Date();
  }),
});

