import { motion } from 'framer-motion';

const pageVariants = {
    initial: {
      opacity: 0,
    //   x: "-100vw",
      scale: 1
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.3
    }
};
  
const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.7
};

/**
 * Page Transition Component
 * @param {*} props 
 */
export const PageTransition = (props) => (
  <motion.div       
    initial="initial"
    animate="in"
    exit="out"
    variants={pageVariants}
    transition={pageTransition}
  >

    {props.children}

  </motion.div>
) 