import React from 'react';

// Components
import { PageButton, PageSubtitle, PageTitle } from '../../components/Page/Page';
import { ZwanLogo } from '../../components/domain/Zwan/Zwan';

// Utils
import { playIcon } from '../../components/Page/Icons';

import { Link, NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { PageTransition } from '../../components/Transitions/PageTransitions';
import { SoftZoomInOnHover } from '../../components/Transitions/ComponentTransitions';

/**
 * Introduction View Component
 */
export default class Appuntamento extends React.Component {

    render() {
        return (
            <PageTransition>
                <div className="h-screen flex flex-col items-center justify-center text-center text-white">

                    <ZwanLogo />
                    <PageTitle text='Grazie, presto riceverai un nostro feedback!' />

                </div>
            </PageTransition>

        );
    }
}